import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frontmatter",
      "style": {
        "position": "relative"
      }
    }}>{`Frontmatter`}<a parentName="h1" {...{
        "href": "#frontmatter",
        "aria-label": "frontmatter permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Frontmatter is the stuff you put at the very top of your markdown files to control everything from the title, page description, images, and SEO. On this site, we also use it to control what appears in the global header navigation, as well as how the sidebar gets rendered in each section. It's therefore quite important to know how to structure your frontmatter so that your file appears in the place you want it to.`}</p>
    <h2 {...{
      "id": "title",
      "style": {
        "position": "relative"
      }
    }}>{`Title`}<a parentName="h2" {...{
        "href": "#title",
        "aria-label": "title permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`title-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`title[String] - Display title used for Header,Sidenav, and SEO.  
title: Frontmatter
`}</code></pre>
    <blockquote>
      <h3 parentName="blockquote" {...{
        "id": "the-title-rule",
        "style": {
          "position": "relative"
        }
      }}>{`The Title Rule`}<a parentName="h3" {...{
          "href": "#the-title-rule",
          "aria-label": "the title rule permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h3>
      <p parentName="blockquote">{`This specifies that we read and display the title in the `}<strong parentName="p">{`frontmatter first`}</strong>{`, then the `}<strong parentName="p">{`first h1 (#) in the file`}</strong>{`, then we `}<strong parentName="p">{`fall back to the name of the file`}</strong>{`.`}</p>
    </blockquote>
    <p>{`The "title" attribute is used for the title meta attribute for SEO. The title rule still applies - it is global.`}</p>
    <h2 {...{
      "id": "order",
      "style": {
        "position": "relative"
      }
    }}>{`Order`}<a parentName="h2" {...{
        "href": "#order",
        "aria-label": "order permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This is a frontmatter attribute unique to this site which we use to define the order in which different pages appear in the sidebar navigation.`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`order-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`order[Integer] - The order in which this file is displayed on the sidenav.
order: 0
`}</code></pre>
    <blockquote>
      <h3 parentName="blockquote" {...{
        "id": "ordering",
        "style": {
          "position": "relative"
        }
      }}>{`Ordering`}<a parentName="h3" {...{
          "href": "#ordering",
          "aria-label": "ordering permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h3>
      <p parentName="blockquote">{`Order in the sidenav is based on lowest number (TOP) to highest (BOTTOM). Files that don't have an order are applied AFTER files that have order in alphabetical order. If this file isn't rendered on the sidenav (i.e. Top section files) this won't matter.`}</p>
    </blockquote>
    <p>{`The "order" is `}<strong parentName="p">{`relative to the current directory`}</strong>{`, so you can't take a page deeper in the directory and put it on the top level of the sidebar. For example, `}<inlineCode parentName="p">{`/learn/module-1/value.mdx`}</inlineCode>{` with an order of 0 will just appear at the top of the `}<inlineCode parentName="p">{`module-1`}</inlineCode>{` dropdown in the sidenav and not at the top of the `}<inlineCode parentName="p">{`learn`}</inlineCode>{` sidenav.`}</p>
    <h2 {...{
      "id": "language-selector",
      "style": {
        "position": "relative"
      }
    }}>{`Language Selector`}<a parentName="h2" {...{
        "href": "#language-selector",
        "aria-label": "language selector permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This is a frontmatter attribute unique to this site which defines whether users will be able to see the different languages available for the particular page they are currently on.`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`language-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`hideLanguageSelector[Boolean] - Language selectors render by default, setting this to true for your page allows you to hide it.
hideLanguageSelector: false
`}</code></pre>
    <h2 {...{
      "id": "header-navigation",
      "style": {
        "position": "relative"
      }
    }}>{`Header Navigation`}<a parentName="h2" {...{
        "href": "#header-navigation",
        "aria-label": "header navigation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The header options are an easy way of adding internal files to the global header. Files specified in header.mdx gets applied `}<strong parentName="p">{`after`}</strong>{` these. Files are organised in the header based on lowest number (LEFT) to highest (RIGHT). "Home" is `}<strong parentName="p">{`always`}</strong>{` first, then files with headerOrder, then links in header.mdx.`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`header-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`header[String] - Whether this File should be rendered in the Header Navigation.
header: true

headerOrder[Integer] - The order in which this file will be displayed on the header.
headerOrder: 0
`}</code></pre>
    <h2 {...{
      "id": "seo",
      "style": {
        "position": "relative"
      }
    }}>{`SEO`}<a parentName="h2" {...{
        "href": "#seo",
        "aria-label": "seo permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`So you want your page to rank well, and have a nice image and description when you share it on social media to show your friends the awesome work you've done? We've got you covered.`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`image-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`featuredImage[String] - Image to use when a link is shared (i.e. Twitter/Facebook)
featuredImage: "content/images/test.png"
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`The "image" attribute `}<strong parentName="p">{`must`}</strong>{` be a path to the images in `}<inlineCode parentName="p">{`content/images/`}</inlineCode></p>
    </blockquote>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`keywords-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`keywords[String] - Comma separated keywords used for SEO
keywords: "cool, sweet, awesome"
`}</code></pre>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`description-example.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`description[String] - Description of this page for SEO.
description: "The most complete guide to writing frontmatter in mdx files that you've ever read."
`}</code></pre>
    <p>{`The "description" attribute can also be used for the excerpt that gets rendered and indexed for searching articles on the site.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      